$(function(){
  //Like button stuff
  $(document).on("ajax:success", ".like-button", function() {
    if ($(this).hasClass("is-selected")){
      $(this).removeClass("is-selected");
      $(this).text( parseInt($(this).text()) - 1);
    }else{
      $(this).addClass("is-selected");
      $(this).text( parseInt($(this).text()) + 1);
    }
  });

  $(document).on("ajax:error", ".like-button", function(event) {
    const detail = event.detail;
    const xhr = detail[2];
    if(xhr.status === 401) {
      let errorMessageDiv = $(this).siblings('#error_explanation');
      errorMessageDiv.show();
    }
  });

  //Expand box for analytics
  $(document).on('click', '.expand-box, .fa-caret-down',function(e){
    id = $(this).attr('id')
    $(`#box-${id}`).slideToggle(400)
    $("#" + id).toggleClass("is-open")
    $(this).find("i").toggleClass("fa-caret-down fa-caret-right");
  });
})