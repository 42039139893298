import {autocomplete,  getAlgoliaResults} from "@algolia/autocomplete-js";
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches';
import { html } from 'htm/preact';

const algoliasearch = require('algoliasearch/lite')

const client = algoliasearch(process.env.ALGOLIA_APPLICATION_ID,process.env.ALGOLIA_SEARCH_API_KEY);

window.sources = {
	"autocomplete" : autocomplete,
	"getAlgoliaResults" : getAlgoliaResults,
	"html": html,
	"client" : client,
	"createLocalStorageRecentSearchesPlugin": createLocalStorageRecentSearchesPlugin
}
