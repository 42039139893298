window.addEventListener("load", function() {
  sessionStorage.removeItem("sliderHistory");
});

//Scroll to top when slider is loaded. Can be used with other frames too
document.addEventListener("turbo:frame-load", function(event) {
  if (event.target.id === "right-slider") {
    event.target.scrollTop = 0;
  }
});

let InternalNavigation = {
  addToHistory: function(text, url) {
    let history = JSON.parse(sessionStorage.getItem("sliderHistory") || "[]");
    let lastItem = history[history.length -1];
    if(lastItem && lastItem.url == url){
      //Do nothing, avoid repeating same url over and over again
    }else{
      history.push({ text, url });
    }
    sessionStorage.setItem("sliderHistory", JSON.stringify(history));
  },

  navigateBack: function() {
    let history = JSON.parse(sessionStorage.getItem("sliderHistory") || "[]");
    if (history.length >= 1) {
      history.pop(); // 2 pops are needed
      history.pop();
      sessionStorage.setItem("sliderHistory", JSON.stringify(history));
    }
  },

  updateBackButton: function() {
      let history = JSON.parse(sessionStorage.getItem("sliderHistory") || "[]");
      if (history.length >= 1) {
          let last = history[history.length - 1];
          let backButton = $("#slider-back-button");
          backButtonText = backButton.find("#back-button-text")
          backButtonText.text(last.text);
          backButton.attr('href', last.url);
          backButton.css("display", "block")
      }
  },

  clearHistory: function() {
    sessionStorage.removeItem("sliderHistory");
  }
};

window.InternalNavigation = InternalNavigation;